import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./services.module.scss"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      diagnosticImage: file(relativePath: { eq: "diagnostic_image.png" }) {
        childImageSharp {
          fluid(maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      diseaseImage: file(relativePath: { eq: "disease_image.png" }) {
        childImageSharp {
          fluid(maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      analyticsImage: file(relativePath: { eq: "analytics_image.png" }) {
        childImageSharp {
          fluid(maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={styles.servicesMain}>
      <Container fluid>
        <div className={styles.servicesCont}>
          <h2>Services</h2>
          <p>
            SensusDx brings together high-quality diagnostics, data science and
            global health expertise to help stakeholders, governments and health
            systems optimize their patient care delivery to improve health
            outcomes.
          </p>
        </div>
      </Container>

      <div className={styles.itemsCont}>
        <Row>
          <Col lg={4} className={styles.item}>
            <div>
              <div className={styles.image}>
                <Img fluid={data.diagnosticImage.childImageSharp.fluid} />
              </div>
              <h6>Diagnostic Testing</h6>
              <p>
                Working to optimize cost effective diagnostic solutions ranging
                from high quality lateral flow tests to next generation
                molecular technologies
              </p>
            </div>
          </Col>
          <Col lg={4} className={styles.item}>
            <div>
              <div className={styles.image}>
                <Img fluid={data.diseaseImage.childImageSharp.fluid} />
              </div>
              <h6>Disease treatment and management</h6>
              <p>
                Developing disease management and treatment programs that not
                only aim to improve patient outcomes but also reduce cost of
                treatment
              </p>
            </div>
          </Col>
          <Col lg={4} className={styles.item}>
            <div>
              <div className={styles.image}>
                <Img fluid={data.analyticsImage.childImageSharp.fluid} />
              </div>
              <h6>Data Analytics</h6>
              <p>
                Integrated connectivity and data powered services, allowing
                SensusDx to analyse health data and develop effective health
                outcomes that can make a difference
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Services
