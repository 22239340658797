import React from "react"

import Slider from "react-slick"

// import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"

import styles from "./stats.module.scss"

// const Stats = () => (
//   <div className={styles.statsMain + " sec"}>
//     <Container fluid>
//       <Row>
//         <Col lg={2} md={4} sm={6}>
//           <div className={styles.item}>
//             <h2>7.7 Bn</h2>
//             <p>
//               Current <strong>World Population</strong> estimate
//             </p>
//           </div>
//         </Col>
//         <Col lg={2} md={4} sm={6}>
//           <div className={styles.item}>
//             <h2>177 Mn</h2>
//             <p>
//               Adults infected with <strong>Hepatitis C</strong> globally
//             </p>
//           </div>
//         </Col>
//         <Col lg={2} md={4} sm={6}>
//           <div className={styles.item}>
//             <h2>2 Bn</h2>
//             <p>
//               Adults infected with <strong>Hepatitis B</strong> globally
//             </p>
//           </div>
//         </Col>
//         <Col lg={2} md={4} sm={6}>
//           <div className={styles.item}>
//             <h2>400,000</h2>
//             <p>
//               People die each year <strong>due to Hepatitis C</strong>
//             </p>
//           </div>
//         </Col>
//         <Col lg={2} md={4} sm={6}>
//           <div className={styles.item}>
//             <h2>780,000</h2>
//             <p>
//               People die each year <strong>due to Hepatitis B</strong>
//             </p>
//           </div>
//         </Col>
//         <Col lg={2} md={4} sm={6}>
//           <div className={styles.item}>
//             <h2>1 Mn</h2>
//             <p>
//               deaths <strong>caused by Liver Cirrhosis</strong> annually
//             </p>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   </div>
// )

class Stats extends React.Component {
  render() {
    var settings = {
      dots: false,
      dotsClass: "slick-dots slick-thumb stats-dots",
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            dots: true
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            dots: true
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            dots: true
          },
        },
      ],
    }
    return (
      <div className={styles.statsMain + " sec"}>
        <Slider {...settings}>
          <div>
            <div className={styles.item}>
              <h2>7.7 Bn</h2>
              <p>
                Current <strong>World Population</strong> estimate
              </p>
            </div>
          </div>
          <div>
            <div className={styles.item}>
              <h2>177 Mn</h2>
              <p>
                Adults infected with <strong>Hepatitis&nbsp;C</strong> globally
              </p>
            </div>
          </div>
          <div>
            <div className={styles.item}>
              <h2>2 Bn</h2>
              <p>
                Adults infected with <strong>Hepatitis&nbsp;B</strong> globally
              </p>
            </div>
          </div>
          <div>
            <div className={styles.item}>
              <h2>400,000</h2>
              <p>
                People die each year <strong>due to Hepatitis&nbsp;C</strong>
              </p>
            </div>
          </div>
          <div>
            <div className={styles.item}>
              <h2>780,000</h2>
              <p>
                People die each year <strong>due to Hepatitis&nbsp;B</strong>
              </p>
            </div>
          </div>
          <div>
            <div className={styles.item}>
              <h2>1 Mn</h2>
              <p>
                deaths <strong>caused by Liver Cirrhosis</strong> annually
              </p>
            </div>
          </div>
        </Slider>
      </div>
    )
  }
}

export default Stats
