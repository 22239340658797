import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import Services from "../components/services"
import Stats from "../components/stats"
import Market from "../components/market"
import Banner from "../components/banner"
import Contact from "../components/contact"

import "../theme/app.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="SensusDx, Inc." />
    <Hero />
    <Services />
    <Stats />
    <Market />
    <Banner />
    <Contact />
  </Layout>
)

export default IndexPage
