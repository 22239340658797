import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import styles from "./market.module.scss"

const Market = () => {
  const data = useStaticQuery(graphql`
    query {
      imageData: file(relativePath: { eq: "bg_test.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className={styles.marketMain + " sec"} id="market">
      <Container>
        <Row>
          <Col lg={6}>
            <h2>Why Choose Us</h2>
            <p>
              Over 30-years industry expertise in pharmaceuticals, medical
              diagnostics and disease management landscape
            </p>
            <p>
              Provide broad access to highly effective diagnostic testing
              covering established technologies and next generation molecular
              testing at responsible prices
            </p>
            <p>
              Partners with pharmaceutical leaders to offer the complete test
              and treat package that performs
            </p>
            <p>
              Pioneered major global public health initiatives and disease
              elimination programmes with new testing and digital technologies.
            </p>
            <p>
              Experts in health data analytics and bio-surveillance reporting,
              along with international regulatory and clinical affairs,
              logistics and commercial expertise
            </p>
            <p>
              Focused on outcome-based healthcare, with the goal of increased
              reach and reducing costs.
            </p>
            <p>
              Aligned with the 2030 Global Viral Hepatitis Elimination Goals and
              the global 90-90-90 HIV Goals.
            </p>
          </Col>
          <Col lg={6}>
            <div className={styles.imageCont}>
              <Img fluid={data.imageData.childImageSharp.fluid} className={styles.image} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Market
