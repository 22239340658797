import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"

import styles from "./hero.module.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      imageData: file(relativePath: { eq: "bg_woman.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.imageData.childImageSharp.fluid}
      className={styles.heroMain}
    >
      <Container fluid>
        <div className={styles.heroCont}>
          <h2>Test, Treat and Transform Lives</h2>
          <p>
            ‘We are a progressive health services company combining diagnostics, patient care and data innovation to scale population based patient care.’
          </p>

          <a className="button-clear" href="#contact">
            Contact Us
          </a>
          <a className="button-green" href="#market">
            Discover more
          </a>
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default Hero
